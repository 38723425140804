import clsx from "clsx";

export const container = clsx(
    "relative w-full max-w-[360px] h-[300px] select-none overflow-hidden rounded-lg shadow-lg",
);
export const img = clsx("absolute inset-0 h-full w-full object-cover");
export const sliderHandle = clsx(
    "absolute inset-y-0 w-0.5 cursor-pointer bg-offWhite/80 shadow-xl transform -translate-x-1/2 shadow-sliderHandle",
);
export const handleOnSlider = clsx(
    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90",
);
export const caption = clsx(
    "w-full mt-2 text-center text-xxs text-black h-4",
);