import { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownArrowIcon } from "@assets/icons";
import { DropdownMenu } from "@components";
import { navItem as navItemStyle } from "@components/DropdownMenu/styles";

export default function NavItem({ route }) {
    const [isOpen, setDropdownOpen] = useState(false);
    const closeDropdown = () => setDropdownOpen(false);

    const dropdownProps = {
        isOpen,
        className: "h-[10px] ml-4",
    };

    const menuProps = {
        closeDropdown,
        isOpen,
        items: route.nested,
    };

    return (
        <div className="relative">
            <Link
                key={route.display}
                to={route.path}
                className={navItemStyle}
                onMouseEnter={() => setDropdownOpen(true)}
                onClick={() => setDropdownOpen(!isOpen)}
            >
                <div className="flex items-center">
                    {route.display}
                    {route.nested && <DropdownArrowIcon {...dropdownProps} />}
                </div>
            </Link>
            <DropdownMenu {...menuProps} />
        </div>
    );
}
