import React, { useEffect } from "react";
import { LoadingAnimation } from "../../..";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export default function AuthCallback() {
    const { handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const processCallback = async () => {
            try {
                const result = await handleRedirectCallback();
                const { appState } = result;
                navigate(appState?.returnTo || "/");
            } catch (error) {
                console.error("Error handling redirect callback:", error);
            }
        };

        processCallback();
    }, [handleRedirectCallback, navigate]);

    return (
        <div className={"mx-2 h-auto w-full"}>
            <LoadingAnimation />
        </div>
    );
}
