import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";
import { BlackLogo } from "@assets/icons";

export default function NoAccess() {
    const { loginWithRedirect } = useAuth0();
    const { pathname } = window.location;

    const onLogout = () => {
        localStorage.clear();
        loginWithRedirect({
            appState: { returnTo: pathname },
        });
    };
    return (
        <div className={parentStyle}>
            <div className="flex max-w-[600px] flex-col items-center">
                <BlackLogo className="mb-4 h-16 w-auto" />
                <div className="text-4xl">403 Forbidden</div>
                <div className="text-2xl">You do not have access here.</div>
                <div className="mt-10 flex w-full justify-between">
                    <div
                        onClick={onLogout}
                        className="hover:cursor-pointer hover:underline"
                    >
                        Try login again
                    </div>
                    <div>|</div>
                    <a href="https://ctrees.org" className="hover:underline">
                        Go to CTrees.org
                    </a>
                </div>
            </div>
        </div>
    );
}

const parentStyle = clsx(
    "w-full h-screen bg-white flex justify-center items-center",
);
