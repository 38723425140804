import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

import { DottedDivider, Button } from "@components";
import { LeftCol, RightCol, FeaturesSlider } from "./components";
import * as styles from "./styles";

export default function Feature(props) {
    const selectedTabTitle = props.tabs[props.selectedTab];
    const tabContent = props.tabsContent[selectedTabTitle];

    const motionDivProps = {
        key: props.selectedTab,
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
        transition: { duration: 0.2 },
        className: "lg:w-2/3",
    };

    return (
        <AnimatePresence mode="wait">
            <motion.div {...motionDivProps}>
                <div className={styles.title}>{selectedTabTitle}</div>
                <DottedDivider className="mt-4 hidden w-full lg:flex" />
                <div className={styles.desc}>{tabContent.desc}</div>
                {tabContent.productLink && (
                    <Button to={tabContent.productLink} className={btnStyle}>
                        Launch {tabContent.productName}
                    </Button>
                )}
                <DottedDivider className="mb-10 mt-4 hidden w-full lg:flex" />
                <FeaturesSlider features={tabContent.features} />
                <div className={styles.rowContainer}>
                    <LeftCol tabContent={tabContent} />
                    <RightCol tabContent={tabContent} />
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

export function LinkText({ to, className, children, external }) {
    return (
        <Link
            to={to}
            target={external ? "_blank" : "_self"}
            rel="noopener noreferrer"
            className={clsx(className + linkTextStyle)}
        >
            {children}
        </Link>
    );
}

const linkTextStyle = clsx(" flex items-center text-black/50 underline pt-2");
const btnStyle = clsx("mb-10 w-2/3 text-sm lg:w-1/3 lg:text-base");
