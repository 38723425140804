import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function FeaturesSlider({ features }) {
    return (
        <Swiper
            style={{
                "--swiper-theme-color": "#46CE84",
                "max-width": "100%",
            }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true, className: "bg-green" }}
            loop={false}
        >
            {features.map(({ img, title, desc, leftSideText = false }) => {
                const BlueBox = () => (
                    <div
                        className={clsx(
                            blueBoxStyle +
                                (leftSideText
                                    ? ` lg:pl-14 ${leftStyle}`
                                    : ` lg:pr-14 ${rightStyle}`),
                        )}
                    >
                        <div className="mb-4 text-xl font-bold">{title}</div>
                        <div className="">{desc}</div>
                    </div>
                );
                return (
                    <SwiperSlide className="mb-10 flex flex-col lg:flex-row">
                        {leftSideText && <BlueBox />}
                        <img
                            src={img}
                            alt={desc}
                            className={clsx(
                                imgStyle +
                                    (leftSideText ? rightStyle : leftStyle),
                            )}
                        />
                        {!leftSideText && <BlueBox />}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

const imgStyle = clsx("h-[300px] lg:w-3/5 object-cover object-left");
const blueBoxStyle = clsx(
    "relative flex lg:w-2/5 flex-col justify-center bg-blue p-8 pr-12",
);
const leftStyle = clsx(" lg:rounded-l-xl lg:rounded-tr-none rounded-t-xl");
const rightStyle = clsx(" lg:rounded-r-xl lg:rounded-bl-none rounded-b-xl");
