import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { routesFlat } from "@routes";
import { BlackLogo } from "@assets/icons";
import { getBgColor, getScrollOpacity } from "../../../helpers";
import { Toggle } from "../";

export default function Header(props) {
    const currentRoute = useLocation();

    const [scrollOpacity, setScrollOpacity] = useState(0.2);
    const bgColor = getBgColor(
        currentRoute.pathname,
        scrollOpacity,
        props.isOpen,
    );

    useEffect(() => {
        const handleScroll = () => {
            const opacity = getScrollOpacity();
            setScrollOpacity(opacity);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={parentContainer} style={bgColor}>
            <Link
                to={routesFlat.landing}
                className={`flex ${props.isOpen ? "fixed" : ""}`}
            >
                <BlackLogo
                    withText={true}
                    className="ml-4 h-20 max-w-[150px]"
                />
            </Link>
            <Toggle {...props} />
        </div>
    );
}

const parentContainer = clsx(
    "fixed top-0 z-40 flex w-full min-h-[80px] justify-between lg:hidden",
);
