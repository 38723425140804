import Lottie from "react-lottie";
import { Button } from "@components";
import { routesFlat } from "@routes";
import * as lottie from "@assets/lottie";
import * as styles from "./styles";

const lineOptions = {
    ...lottie.defaultOptions,
    animationData: lottie.Line,
};

export default function Hero() {
    const btnProps = {
        to: routesFlat.products,
        primary: false,
        className: "w-[200px]",
    };
    return (
        <>
            <div className={styles.parentContainer}>
                {/* HALF CIRCLE */}
                <div className="relative h-screen w-full">
                    <div
                        className={styles.halfCircle}
                        style={{
                            top: "-107vh",
                            clipPath: "ellipse(200% 50% at 50% 100%)",
                        }}
                    ></div>
                </div>
                {/* SUB HEADER TEXT */}
                <div className={styles.textContainer}>
                    <div className={styles.headerText}>
                        Track forest carbon anywhere on the planet.
                    </div>
                    <div className={styles.subHeaderText}>
                        CTrees is a science nonprofit bringing actionable data
                        and knowledge on natural climate solutions.
                    </div>
                    <Button {...btnProps}>See our data</Button>
                    <div className="mt-10">
                        <Lottie options={lineOptions} height={380} width={50} />
                    </div>
                </div>
            </div>
        </>
    );
}
