export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

const s3Bucket =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/landing/25_01+Data+Card_";
    
export const dataCards = [
    {
        img: `${s3Bucket}JMRV.png`,
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "Measure, report, and verify carbon stocks, emissions, and removals across 263 countries and territories.",
        link: "/jmrv",
        linkDisplayText: "learn more",
    },
    {
        img: `${s3Bucket}LUCA.png`,
        imgAlt: "",
        title: "Land Use Change Alerts",
        subheader:
            "Access near-real-time information on  land use change activities and forest disturbances worldwide.",
        link: "/luca",
        linkDisplayText: "access data",
    },
    {
        img: `${s3Bucket}REDD%2BAI.png`,
        imgAlt: "",
        title: "REDD+AI",
        subheader:
            "Quantify and map tree cover loss and degradation due to logging, fire, and roads in all tropical forests globally.",
        link: "/reddai",
        linkDisplayText: "access data",
    },
    {
        img: `${s3Bucket}Land+Carbon.png`,
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "Estimate changes in carbon at 1-hectare resolution in all forests and non-forest land from 2000 to present.",
        link: "/land-carbon",
        linkDisplayText: "learn more",
    },
    {
        img: `${s3Bucket}Tree+Level.jpeg`,
        imgAlt: "",
        title: "Tree Level Data",
        subheader:
            "Map individual trees at the local to national scale, both inside and outside of forests. ",
        link: "/tree-level",
        linkDisplayText: "learn more",
    },
    {
        img: `https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/Activity+Timelapse/045_frame.png`,
        imgAlt: "",
        title: "Activity Data",
        subheader:
            "Measure historical deforestation, forest degradation, and forest regeneration across the tropics.",
        link: "/activity-data",
        linkDisplayText: "learn more",
    },
];

export const dataSubheader = "";
