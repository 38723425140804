import clsx from "clsx";
import { useState } from "react";
import { DottedDivider } from "@components";

export default function VerticalTabs(props) {
    const [openTab, setOpenTab] = useState(true);

    const handleTabClick = (i) => {
        if (openTab && props.children && props.selectedTab === i) {
            return setOpenTab(false);
        }
        if (props.children) {
            setOpenTab(true);
        }

        props.setSelectedTab(i);
    };

    return (
        <div className={tabContainerStyle}>
            <DottedDivider className="w-full lg:hidden" />
            {props.tabs.map((tab, i) => {
                const selected = props.selectedTab === i;

                return (
                    <>
                        <div key={i} className={tabChildStyle}>
                            <div className="w-4">
                                {selected && (
                                    <div key={i} className={dotStyle} />
                                )}
                            </div>
                            <button
                                key={tab}
                                onClick={() => handleTabClick(i)}
                                className={clsx(
                                    tabStyle +
                                        (selected
                                            ? "text-black"
                                            : "text-black/50"),
                                )}
                            >
                                {tab}
                            </button>
                        </div>
                        <DottedDivider className="w-full last:hidden" />

                        {selected && openTab && props.children && (
                            <div className="w-full">{props.children}</div>
                        )}
                    </>
                );
            })}
        </div>
    );
}

const tabStyle = clsx(
    " flex lg:w-[300px] w-full items-center space-x-2 lg:px-4 px-2 py-2 text-left",
    " lg:text-2xl text-lg uppercase tracking-wider focus:outline-none text-wrap ",
);
const tabContainerStyle = clsx(
    "relative flex lg:w-1/3 flex-col items-start p-4 w-full",
);
const dotStyle = clsx("lg:h-4 lg:w-4 h-3 w-3 rounded-full bg-green");
const tabChildStyle = clsx("flex w-full items-center px-4 py-2");
