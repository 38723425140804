import clsx from "clsx";

export const title = clsx(
    "lg:mt-4 mt-8 lg:text-6xl text-3xl font-bold uppercase tracking-wider",
);
export const desc = clsx("lg:mt-2 lg:py-8 py-6 lg:text-2xl tracking-wider");

export const rowContainer = clsx(
    "flex flex-col lg:flex-row justify-between w-full",
);
export const colContainer = clsx("flex flex-col lg:w-1/2");

export const featTitle = clsx(
    "uppercase font-bold tracking-widest text-sm mt-10 mb-2",
);
export const featCatTitle = clsx("text-black/50 capitalize mt-4");
