import { Link } from "react-router-dom";
import { formatDateMonth } from "@helpers";
import { feTagsFlat } from "@helpers/constants";
import ScaleOnHoverDiv from "../../ScaleOnHoverDiv";
import * as styles from "./styles";

export default function BlueCard({ article, className }) {
    const tags = article.product_tags.concat(article.article_tags);

    return (
        <ScaleOnHoverDiv className={className + " flex justify-center"}>
            <Link to={`/news/${article.uri}`} className={styles.cardWrapper}>
                <div className={styles.date}>
                    {formatDateMonth(article.publish_date)}
                </div>
                <div className="line-clamp-3 text-lg font-bold">
                    {article.title}
                </div>
                <div className={styles.summary}>{article.summary}</div>
                <div className="absolute bottom-0 mb-6 flex">
                    {tags?.map((tag) => (
                        <div className={styles.tag} key={tag}>{feTagsFlat[tag]}</div>
                    ))}
                </div>
            </Link>
        </ScaleOnHoverDiv>
    );
}
