export default function ColorfulDotsCircle({ className, alt }) {
    return (
        <div role="img" aria-label={alt} className={className}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 451 449"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="229.587"
                    cy="226.709"
                    r="184.827"
                    stroke="white"
                    strokeWidth="3"
                />
                <circle
                    cx="229.585"
                    cy="226.707"
                    r="144.466"
                    stroke="white"
                    strokeWidth="3"
                />
                <circle
                    cx="229.585"
                    cy="226.707"
                    r="219.833"
                    stroke="white"
                    strokeWidth="3"
                />
                <circle cx="232.611" cy="411.563" r="8" fill="#FF6B6B" />
                <circle cx="64.8145" cy="141.698" r="8" fill="#46CE84" />
                <circle cx="237.58" cy="42.3311" r="8" fill="#FF6B6B" />
                <circle cx="367.752" cy="397.288" r="8" fill="#46CE84" />
                <circle cx="296.783" cy="397.288" r="8" fill="#46CE84" />
                <circle cx="8.25195" cy="229.423" r="8" fill="#46CE84" />
                <circle cx="74.752" cy="379.663" r="8" fill="#46CE84" />
                <circle cx="393.252" cy="139.522" r="8" fill="#B6D9FF" />
                <circle cx="91.6191" cy="53.6904" r="8" fill="#46CE84" />
                <circle cx="393.252" cy="80.7412" r="8" fill="#46CE84" />
                <circle cx="46.1113" cy="234.708" r="8" fill="#FF6B6B" />
                <circle cx="226.252" cy="81.4424" r="8" fill="#FF6B6B" />
                <circle cx="372.643" cy="243.78" r="8" fill="#DFF841" />
                <circle cx="330.549" cy="330.706" r="8" fill="#46CE84" />
                <circle cx="181.088" cy="440.04" r="8" fill="#46CE84" />
                <circle cx="94.4707" cy="347.921" r="8" fill="#B6D9FF" />
                <circle cx="221.58" cy="368.409" r="8" fill="#46CE84" />
                <circle cx="118.486" cy="133.698" r="8" fill="#46CE84" />
                <circle cx="105.002" cy="297.954" r="8" fill="#46CE84" />
                <circle cx="261.018" cy="8.05957" r="8" fill="#46CE84" />
                <circle cx="442.916" cy="170.454" r="8" fill="#DFF841" />
                <circle cx="38.1113" cy="116.013" r="8" fill="#DFF841" />
            </svg>
        </div>
    );
}
