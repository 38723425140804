import { routesFlat } from "@routes";

export default function getBgColor(
    currentRoute,
    scrollOpacity,
    isMobileOpen = false,
) {
    if (isMobileOpen) {
        {
            return { backgroundColor: `rgba(70, 206, 132)` }; // bg-green
        }
    }
    if (
        currentRoute.includes(routesFlat.news) ||
        currentRoute.includes(routesFlat.landCarbon) ||
        currentRoute.includes(routesFlat.activityData)
    ) {
        return { backgroundColor: `rgba(70, 206, 132, ${scrollOpacity})` }; // bg-green
    }

    if (currentRoute.includes(routesFlat.treeLevel)) {
        return { backgroundColor: `rgba(182, 217, 255, ${scrollOpacity})` }; // bg-blue
    }
    return { backgroundColor: `rgba(255, 255, 255, ${scrollOpacity})` };
}
