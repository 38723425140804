import clsx from "clsx";

export const parentContainer = clsx(
    "mb-10 flex w-full flex-col md:flex-row flex-nowrap justify-between lg:mb-40",
);
export const leftColContainer = clsx("md:mr-20 flex md:w-2/3 flex-col items-start");
export const rightColContainer = clsx("mt-10 lg:mt-20 md:mt-0 p-4 rounded-md md:ml-10 flex h-full bg-gray lg:bg-transparent md:w-1/3 flex-col");

export const rightColHeader = clsx(
    "uppercase font-bold tracking-widest text-xs md:text-sm first:mt-0 mt-6",
);
export const date = clsx("text-black/40 text-xs md:text-sm mb-2 font-semibold uppercase tracking-wider");
export const text = clsx("mt-2 text-sm");
