import { useState } from "react";
import * as global from "@components";

export default function MapTemplate({ constants }) {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {/* HEADER */}
            <global.MetaTagHeader {...constants.metaProps} />

            {/* TEXT IMG BEATS */}
            <div className="mt-[60px] h-iframeScreen w-full">
                {loading && constants.loading}
                {
                    <iframe
                        title="map"
                        src={constants.url}
                        className={loading ? "" : "h-full w-full"}
                        onLoad={() => setLoading(false)}
                        allow="clipboard-write"
                    />
                }
            </div>
        </>
    );
}
