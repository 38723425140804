import { useState } from "react";
import clsx from "clsx";
import { UnderlinedSectionHeader } from "@components";
import { Hero, Tabs, Feature } from "./components";
import { tabs, tabsContent } from "./constants";

export default function DataOverview() {
    const [selectedTab, setSelectedTab] = useState(0);
    const featureProps = { selectedTab, tabs, tabsContent: tabsContent() };
    const tabProps = { ...featureProps, setSelectedTab };

    return (
        <>
            <Hero />
            <div className={parentContainer}>
                {/* Desktop */}
                <div className="hidden w-[90%] lg:flex">
                    <Tabs {...tabProps} />
                    <Feature {...featureProps} />
                </div>
                {/* Mobile */}
                <div className={mobileContainer}>
                    <UnderlinedSectionHeader className="mb-6">
                        All Tools
                    </UnderlinedSectionHeader>
                    <Tabs {...tabProps}>
                        <Feature {...featureProps} />
                    </Tabs>
                </div>
            </div>
        </>
    );
}

const parentContainer = clsx("my-20 flex w-full items-center justify-center");
const mobileContainer = clsx("flex w-[90%] items-center flex-col lg:hidden");
