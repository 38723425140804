import clsx from "clsx";
import { Link } from "react-router-dom";
import { routesFlat, routes } from "@routes";
import { useState } from "react";
import { DropdownArrowIcon } from "@assets/icons";

export default function Links() {
    const navRoutes = routes.filter((route) => route.displayInNav);

    return (
        <div className={containerStyle}>
            <Link to={routesFlat.landing} className={textStyle}>
                Home
            </Link>
            {navRoutes.map((route) => (
                <MainLink route={route} key={route.path} />
            ))}
        </div>
    );
}

const MainLink = ({ route }) => {
    const [openNested, setOpenNested] = useState(false);
    return (
        <div key={route.path} className="relative flex flex-col">
            <div className="flex">
                <Link
                    key={route.path}
                    to={route.path}
                    className={clsx(
                        textStyle,
                        route.path ? "cursor-pointer" : "cursor-default",
                    )}
                    onClick={() => setOpenNested(!openNested)}
                >
                    {route.display}
                </Link>
                {route.nested && (
                    <div
                        className={clsx(
                            (openNested ? "rotate-[180deg]" : "") +
                                " ml-2 flex items-center lg:hidden",
                        )}
                    >
                        <DropdownArrowIcon className="h-2" fill={"black"} />
                    </div>
                )}
            </div>
            {route.nested && (
                <>
                    <div className="lg:hidden">
                        {openNested && <NestLink route={route} />}
                    </div>
                    <div className={nestLinkParentStyle}>
                        <NestLink route={route} />
                    </div>
                </>
            )}
        </div>
    );
};

const NestLink = ({ route }) => {
    return (
        <div className={nestLinkContainer}>
            {route.nested.map((nestedRoute) => (
                <Link
                    key={nestedRoute.path}
                    to={nestedRoute.path}
                    className={clsx(textStyle, "text-xxs")}
                >
                    {nestedRoute.display}
                </Link>
            ))}
        </div>
    );
};

const containerStyle = clsx(
    "flex flex-col relative items-start gap-6 lg:flex-row lg:justify-end lg:gap-12",
);
const nestLinkParentStyle = clsx(
    "absolute left-0 top-0 mt-8 hidden w-28 lg:block",
);
const nestLinkContainer = clsx(
    "mt-2 flex flex-col gap-4 first:mt-6 first:lg:mt-0",
);
const textStyle = clsx(
    "text-sm uppercase font-bold lg:font-normal tracking-wider ",
);
