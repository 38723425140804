import { ImgTimelapse } from "@components";

export const hero = {
    bgColor: "green",
    headerImg:
        "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/activity-data.png",
    title: "Activity Data",
    subheader:
        "See deforestation, forest degradation, and forest regeneration across the tropics",
    desc: "CTrees activity data offers agencies and organizations seeking to reduce deforestation and restore landscapes with independent scientific data on tree cover loss, forest degradation, and regrowth. ",
};

const s3URI =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/Activity+Timelapse";

const timelapseImgs = [
    { year: "'17", src: `${s3URI}/003_frame.png` },
    { year: "'18", src: `${s3URI}/005_frame.png` },
    { year: "'19", src: `${s3URI}/007_frame.png` },
    { year: "'20", src: `${s3URI}/008_frame.png` },
    { year: "'21", src: `${s3URI}/021_frame.png` },
    { year: "'22", src: `${s3URI}/033_frame.png` },
    { year: "'23", src: `${s3URI}/045_frame.png` },
];

const timelapseCoords = { lat: -6.0008, lon: -55.2136 };
const timelapseCaption =
    "logging roads construction in Morais Almeida municipality (state of Pará, Brazil)";

export const features = [
    {
        title: "Precise monitoring of deforestation",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/activity-beat-1.png",
        list: [
            "Identify forest cover change and attribute it to deforestation.",
            "Evaluate historical maps and statistics, to pinpoint areas for intervention.",
            "Establish accurate baselines for rates of deforestation based on maps and design-based sampling.",
        ],
    },
    
    {
        title: "Uncover forest degradation",
        img: (
            <ImgTimelapse
                images={timelapseImgs}
                coordinates={timelapseCoords}
                caption={timelapseCaption}
            />
        ),
        list: [
            "Identify disturbances causing forest degradation with unparalleled accuracy and resolution.",
            "Map the extent of each degradation type such as logging, fire and road construction.",
            "Estimate intensity of degradation.",
        ],
    },
    {
        title: "Track forest regrowth",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/activity-beat-3.png",

        list: [
            "Identify disturbances causing forest degradation with unparalleled accuracy and resolution.",
            "Map the extent of each degradation type such as logging, fire and road construction. ",
            "Estimate intensity of degradation. ",
        ],
    },
    {
        title: "Data for policy and investments",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/activity-beat-4.png",
         list: [
            "Set up customized monitoring systems for bi-weekly, monthly, and annual reporting.",
            "Automatically estimate performance in custom areas or administrative boundaries.",
            "Assess performance of REDD+, zero-deforestation commodity, and landscape restoration programs. ",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Measure carbon in all forest and non-forest land globally?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
