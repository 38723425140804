import clsx from "clsx";
import { HeroOverlay } from "@components";

// RIP BIRD: https://images.pexels.com/photos/70069/pexels-photo-70069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2

export default function Hero() {
    return (
        <div className={containerStyle}>
            <HeroOverlay img="https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/data-overview/data-overview-banner.png" />
            <div className={titleStyle}>data</div>
            <div className="z-10 mt-8 lg:w-1/2">
                <div className={descStyle}>
                    Backed by peer-reviewed science, CTrees datasets are
                    designed to bring confidence and clarity to efforts to
                    protect and restore ecosystems on land globally at any
                    scale.
                </div>
            </div>
        </div>
    );
}

const containerStyle = clsx(
    "lg:px-20 flex lg:flex-row flex-col lg:h-[500px] h-[550px] w-screen items-center lg:justify-between justify-center relative",
);
const titleStyle = clsx(
    "lg:w-1/2 lg:text-6xl text-4xl font-bold uppercase text-white z-10",
);
const descStyle = clsx(
    "max-w-[500px] px-10 lg:px-0 w-full lg:text-left text-center lg:text-xl text-white",
);
