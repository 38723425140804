import { GreenCard } from "@components";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function CarouselCards({cards}) {
    return (
        <Swiper
        style={{ "--swiper-theme-color": "#46CE84", "width": "100%", "paddingLeft": 20, "marginBottom": 100}}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={4}
        breakpoints={{
            200: {
                slidesPerView: 1, // mobile screens
            },
            730: {
                slidesPerView: 2, // tablet screens
            },
            1200: {
                slidesPerView: 3, // large screens
            },
            1600: {
                slidesPerView: 4, // x-large screens
            },
        }}
        navigation
        pagination={{ clickable: true, className: "bg-green" }}
        loop={false}
    >
        {cards.map((card, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <GreenCard {...card} key={card.title} />
                        </SwiperSlide>
                    );
                })}
        </Swiper>
    )
}