export default function TargetsAndGrid({ className, alt }) {
    return (
        <div role="img" aria-label={alt} className={className + " md:h-full"}>
            <svg
                className="w-full h-full"
                viewBox="0 0 640 651"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M640 140.438L-611.63 140.437"
                    stroke="url(#paint0_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 214.633L-611.63 214.633"
                    stroke="url(#paint1_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 288.832L-611.63 288.832"
                    stroke="url(#paint2_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 363.027L-611.63 363.027"
                    stroke="url(#paint3_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 437.227L-611.63 437.227"
                    stroke="url(#paint4_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 511.43L-611.63 511.43"
                    stroke="url(#paint5_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 585.625L-611.63 585.625"
                    stroke="url(#paint6_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M640 66.2344L-611.63 66.2343"
                    stroke="url(#paint7_linear_222_6321)"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M571.172 734.023L571.172 -82.955"
                    stroke="white"
                    stroke-opacity="0.2"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M469.797 734.023L469.797 -82.955"
                    stroke="white"
                    stroke-opacity="0.4"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M368.424 734.023L368.424 -82.955"
                    stroke="white"
                    stroke-opacity="0.6"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M267.049 734.023L267.049 -82.955"
                    stroke="white"
                    stroke-opacity="0.8"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M165.674 734.023L165.674 -82.955"
                    stroke="white"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <path
                    d="M64.2988 734.023L64.2989 -82.955"
                    stroke="white"
                    strokeWidth="2.66774"
                    stroke-dasharray="5.34 5.34"
                />
                <circle
                    cx="368.424"
                    cy="511.425"
                    r="8"
                    transform="rotate(152.84 368.424 511.425)"
                    fill="#FF6B6B"
                />
                <circle
                    cx="370.056"
                    cy="513.148"
                    r="71.3375"
                    transform="rotate(152.84 370.056 513.148)"
                    stroke="#FF6B6B"
                    strokeWidth="2.66774"
                />
                <circle
                    cx="267.049"
                    cy="66.2371"
                    r="8"
                    transform="rotate(152.84 267.049 66.2371)"
                    fill="#FF6B6B"
                />
                <circle
                    cx="266.026"
                    cy="62.2998"
                    r="37.3113"
                    transform="rotate(152.84 266.026 62.2998)"
                    stroke="#FF6B6B"
                    strokeWidth="2.66774"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_222_6321"
                        x1="-330.579"
                        y1="140.944"
                        x2="640"
                        y2="140.944"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_222_6321"
                        x1="-330.579"
                        y1="215.139"
                        x2="640"
                        y2="215.139"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_222_6321"
                        x1="-330.579"
                        y1="289.338"
                        x2="640"
                        y2="289.338"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_222_6321"
                        x1="-330.579"
                        y1="363.533"
                        x2="640"
                        y2="363.534"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_222_6321"
                        x1="-330.579"
                        y1="437.733"
                        x2="640"
                        y2="437.733"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_222_6321"
                        x1="-330.579"
                        y1="511.936"
                        x2="640"
                        y2="511.936"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_222_6321"
                        x1="-330.579"
                        y1="586.131"
                        x2="640"
                        y2="586.131"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_222_6321"
                        x1="-330.579"
                        y1="66.7405"
                        x2="640"
                        y2="66.7406"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.613696" stop-color="white" />
                        <stop
                            offset="1"
                            stop-color="white"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}