import { ImgComparisonSlider } from "@components";

export const hero = {
    bgColor: "blue",
    headerImg:
        "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/tree-level.png",
    title: "Tree-Level Data",
    subheader: "Map individual trees at local to national scales",
    desc: "CTrees' tree-level datasets provide information on individual trees at local to national scales, supporting agencies and organizations implementing natural climate solutions.",
};

const s3Bucket =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/Tree+Level+Slider/";

export const features = [
    {
        title: "Trees inside and outside forests",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/tree-level-beat-1.png",
       
        list: [
            "Map all trees, whether they are inside forests or outside forests, such as in savannas, urban, and altered landscapes.",
            "Access accurate information on trees’ location, crown size, height, and estimated carbon content.",
        ],
    },
    {
        title: "Scientific insight",
        img: (
            <ImgComparisonSlider
                imgBefore={`${s3Bucket}Tree-level+slider_right.png`}
                imgBeforeAlt={"Tree level before"}
                imgAfter={`${s3Bucket}Tree-level+slider_left.png`}
                imgAfterAlt={"Tree level after"}
                caption={"canopy height model of the US based on NAIP imagery"}
            />
        ),
        list: [
            "Access information on numbers, size distribution, and spatial patterns of trees across landscapes",
            "Study disturbance and recovery processes, growth patterns, and ecosystem dynamics.",
        ],
    },
    {
        title: "Data for land management policy",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/tree-level-beat-3.png",
        list: [
            "Plan nature-based climate solutions with precise information on tree-level carbon.",
            "Use tree-level data to identify areas for wildfire fuel reduction.",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Map individual trees at local to national scales?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
