import clsx from "clsx";
import { Link } from "react-router-dom";

import { date, text, rightColHeader } from "../styles";
import { routesFlat } from "@routes";
import { RocketIcon } from "@assets/icons";

export default function Featured() {
    return (
        <>
            <div className={rightColHeader}>featured product update</div>
            <Link to={"jmrv-update-2024"}>
                <div className={clsx(date + " mt-2 tracking-wider")}>
                    Nov 15, 2024
                </div>
                <div className={clsx(text + " line-clamp-3")}>
                    CTrees added 2023 carbon data to the Jurisdictional MRV
                    tool.
                </div>
            </Link>

            <Link to={routesFlat.jmrv} className="mt-1 flex text-sm font-bold">
                <RocketIcon />
                <div className="ml-2 border-b-[1px] border-b-red">
                    Launch App
                </div>
            </Link>
        </>
    );
}
