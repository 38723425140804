export const getEmailFromToken = async (token) => {
    try {
        const confirm = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/user/confirm`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (!confirm.ok) {
            throw new Error(`HTTP error! status: ${confirm.status}`);
        }
        return confirm.json();
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};