import clsx from "clsx";
import { Link } from "react-router-dom"

export default function NotFound() {
    return (
        <div className={parentStyle}>
        <div className="max-w-[400px] flex flex-col">
            <div className="text-4xl">Page not found</div>
            <Link className="text-2xl mt-10" to={'/'}>Go back to Home Page</Link>
        </div>
        </div>
    )
}

const parentStyle = clsx(
    "w-full h-screen flex justify-center items-center"
);