import { ImgTimelapse, ImgComparisonSlider } from "@components";

export const hero = {
    bgColor: "green",
    headerImg:
        "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/land-carbon.png",
    title: "Land Carbon Map",
    subheader: "Measure carbon in all forest and non-forest land globally",
    desc: "CTrees’ land carbon maps provide jurisdictions and organizations implementing major climate programs with accurate estimates of carbon at 1-hectare resolution in all forests and non-forest areas, based on CTrees’ global data platform. ",
};

const s3URI =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/";

const timelapseImgs = [
    { year: "'00", src: `${s3URI}Activity+Timelapse/003_frame.png` },
    { year: "'05", src: `${s3URI}Activity+Timelapse/005_frame.png` },
    { year: "'10", src: `${s3URI}Activity+Timelapse/007_frame.png` },
    { year: "'15", src: `${s3URI}Activity+Timelapse/008_frame.png` },
    { year: "'20", src: `${s3URI}Activity+Timelapse/021_frame.png` },
    { year: "'25", src: `${s3URI}Activity+Timelapse/033_frame.png` },
];

const timelapseCoords = { lat: -6.0008, lon: -55.2136 };
const timelapseCaption =
    "logging roads construction in Morais Almeida municipality (state of Pará, Brazil)";

export const features = [
    {
        title: "Historical accuracy for inventories and baselines",
        img: (
            <ImgTimelapse
                images={timelapseImgs}
                coordinates={timelapseCoords}
                caption={timelapseCaption}
            />
        ),
        list: [
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present.",
            "Meet IPCC Tier 3 standards for wall-to-wall mapping, for national greenhouse gas inventories and Paris Agreement nationally determined contributions.",
        ],
    },
    {
        title: "Data for policy and carbon markets",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/land-carbon-beat-2.png",
        list: [
            "Meet accuracy requirements for calculating emission factors at project or jurisdictional levels. ",
            "Establish accurate forest reference emission levels at jurisdiction scales for REDD finance. ",
            "Understand spatial confidence level for prediction of carbon risks and returns.",
        ],
    },
    {
        title: "Comprehensive coverage",
        img: (
            <ImgComparisonSlider
                imgBefore={`${s3URI}Land+Carbon+Slider/land-carbon-slider-left.jpeg`}
                imgBeforeAlt={"Land carbon map before"}
                imgAfter={`${s3URI}Land+Carbon+Slider/land-carbon-slider-right.jpeg`}
                imgAfterAlt={"Land carbon map after"}
                caption={'land carbon mapped over Mexico'}
            />
        ),
        list: [
            "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
            "Understand impacts of climate change, soil, and disturbance processes.",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Measure carbon in all forest and non-forest land globally?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
