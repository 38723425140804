import { motion, useScroll, useSpring } from "framer-motion";

const btnStyles = "fixed z-[60] top-0 right-0 left-0 h-[5px] bg-[darkgray]";

export default function ScrollProgress() {
  const { scrollYProgress } = useScroll();

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return <motion.div className={btnStyles} style={{ scaleX }} />;
}
