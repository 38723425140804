export default function copyToClipboard(text, setCopied) {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            setCopied(true);
            console.log("Text copied to clipboard");
            setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => {
            console.error("Failed to copy: ", err);
        });
}
