import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CMSContext } from "@";
import { LoadingAnimation } from "@components";
import * as helpers from "./helpers";
import { routesFlat } from "@routes";

export default function Login({ children }) {
    const {
        isAuthenticated,
        isLoading,
        user,
        loginWithRedirect,
        getIdTokenClaims,
    } = useAuth0();

    const { pathname } = window.location;
    const { setState } = useContext(CMSContext);
    const navigate = useNavigate();

    useEffect(() => {
        const authenticateUser = async () => {
            try {
                // Wait for Auth0 to fully initialize
                if (isLoading) return;

                if (isAuthenticated && user) {
                    let token = localStorage.getItem("token");

                    // Generate a new token if none exists or if invalid
                    if (!token || !helpers.isTokenValid(token)) {
                        const idTokenClaims = await getIdTokenClaims();
                        token = idTokenClaims.__raw;
                        localStorage.setItem("token", token);
                    }

                    // Check user status
                    const isAdmin = await helpers.isUserAdmin(token);
                    const userObj = await helpers.getEmailFromToken(token);
                    const userEmail = userObj.data.email;
                    const ctreesUser = await helpers.getUserFromEmail(
                        userEmail,
                        token,
                    );

                    // Check admin status
                    const ctreesUserType = ctreesUser.data.user_type;
                    const isCTreesAdmin = ctreesUserType === "ADMIN";
                    const isCTreesUser =
                        isCTreesAdmin || ctreesUserType === "INTERNAL";
                    const adminStatus = !!(isAdmin && isCTreesUser);

                    // Check cms or dev environment
                    const devEnv = process.env.REACT_APP_LOGIN_ENABLED;

                    // if devEnv, user must be admin or internal
                    if (devEnv && !isCTreesUser) {
                        navigate(routesFlat.noAccess);
                    }

                    // if it's cms, user must be admin
                    if (pathname === routesFlat.cms && !isCTreesAdmin) {
                        navigate(routesFlat.noAccess);
                    }

                    setState({
                        token,
                        cTreesUser: ctreesUserType,
                        isAdmin: adminStatus,
                    });
                } else if (!localStorage.getItem("token")) {
                    // Redirect to login if no token exists
                    triggerLogin();
                }
            } catch (error) {
                console.error("Authentication error:", error);
                triggerLogin(); // Redirect to login on failure
            }
        };

        authenticateUser();
    }, [
        isAuthenticated,
        isLoading,
        user,
        getIdTokenClaims,
        navigate,
        pathname,
    ]);

    const triggerLogin = () => {
        loginWithRedirect({
            appState: { returnTo: pathname },
            redirectUri: `${process.env.REACT_APP_HOME_URL}/callback`,
        });
    };

    if (isLoading || (!isAuthenticated && !localStorage.getItem("token"))) {
        return <LoadingAnimation />;
    }

    return children;
}
