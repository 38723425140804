export default function RangeLineChart({ className, alt }) {
    return (
        <div role="img" aria-label={alt} className={className}>
            <svg
                className="w-full h-full"
                viewBox="0 0 405 342"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M404.711 58.6223L0.670258 58.6221"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 100.455L0.670258 100.455"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 142.289L0.670258 142.289"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 184.122L0.670258 184.122"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 225.955L0.670258 225.955"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 267.788L0.670258 267.788"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M404.711 309.622L0.670258 309.622"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M27.1699 271.331L27.1699 341.331"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M60.8145 218.011L60.8145 288.011"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M133.713 126.104L133.713 196.104"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M282.982 79.2412L282.982 149.241"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M165.748 129.939L165.748 196.062"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M315.018 83.0763L315.018 149.2"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M149.342 110.817L149.342 160.544"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M298.611 63.9551L298.611 113.682"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M73.6308 174.475L73.6309 224.349"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M209.229 80.0645L209.229 129.939"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M358.498 36.7793L358.498 86.6534"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M86.4473 182.693L86.4473 245.978"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M103.271 196.104L103.271 259.388"
                    stroke="#46CE84"
                    strokeWidth="3"
                />
                <path
                    d="M116.889 135.214L116.889 228.939"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M266.158 60.622L266.158 154.347"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M179.396 142.345L179.396 236.069"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M328.666 57.6123L328.666 151.337"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M228.9 42.8418L228.9 136.567"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M378.17 0.875932L378.17 94.6007"
                    stroke="#46CE84"
                    strokeWidth="3"
                />
                <path
                    d="M192.678 101.506L192.678 218.833"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M341.947 25.0986L341.947 142.426"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M246.486 26.2656L246.486 116.277"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M42.3261 205.33L42.3262 326.7"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <circle cx="27.3887" cy="308.548" r="8" fill="#FF6B6B" />
                <circle cx="42.5449" cy="266.793" r="8" fill="#B6D9FF" />
                <circle cx="61.0332" cy="255.228" r="8" fill="#FF6B6B" />
                <circle cx="73.8496" cy="201.629" r="8" fill="#FF6B6B" />
                <circle cx="86.666" cy="216.553" r="8" fill="#B6D9FF" />
                <circle cx="103.49" cy="229.963" r="8" fill="#46CE84" />
                <circle cx="117.107" cy="184.293" r="8" fill="#B6D9FF" />
                <circle cx="133.932" cy="163.32" r="8" fill="#FF6B6B" />
                <circle cx="149.561" cy="137.897" r="8" fill="#FF6B6B" />
                <circle cx="165.967" cy="165.218" r="8" fill="#FF6B6B" />
                <circle cx="179.615" cy="191.424" r="8" fill="#FF6B6B" />
                <circle cx="192.896" cy="162.387" r="8" fill="#B6D9FF" />
                <circle cx="209.447" cy="107.219" r="8" fill="#FF6B6B" />
                <circle cx="229.119" cy="91.9209" r="8" fill="#B6D9FF" />
                <circle cx="246.705" cy="75.6758" r="8" fill="#FF6B6B" />
                <circle cx="266.377" cy="109.701" r="8" fill="#FF6B6B" />
                <circle cx="283.201" cy="116.458" r="8" fill="#B6D9FF" />
                <circle cx="298.83" cy="91.0352" r="8" fill="#FF6B6B" />
                <circle cx="315.236" cy="118.355" r="8" fill="#FF6B6B" />
                <circle cx="328.885" cy="106.691" r="8" fill="#B6D9FF" />
                <circle cx="342.166" cy="85.9795" r="8" fill="#FF6B6B" />
                <circle cx="358.717" cy="63.9336" r="8" fill="#FF6B6B" />
                <circle cx="378.389" cy="49.9551" r="8" fill="#46CE84" />
            </svg>
        </div>
    );
}
