import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

export default function Logout() {
    const { logout, loginWithRedirect } = useAuth0();
    const returnToUrl = process.env.REACT_APP_HOME_URL || "https://ctrees.org";
    const { pathname } = window.location;

    const handleLogout = () => {
        console.log(
            "Logging out, redirecting to:",
            process.env.REACT_APP_LOGIN_ENABLED
                ? "login"
                : process.env.REACT_APP_HOME_URL,
        );

        localStorage.clear();
        logout({
            returnTo: returnToUrl,
        });

        if (process.env.REACT_APP_LOGIN_ENABLED) {
            loginWithRedirect({
                appState: { returnTo: pathname }, // Save current pathname in appState
            });
        }
    };

    return (
        <button onClick={handleLogout} className={buttonStyle}>
            Log Out
        </button>
    );
}

export const buttonStyle = clsx(
    "text-xs tracking-wide uppercase z-30 w-auto px-4 py-2 rounded-xl bg-[white] hover:bg-green font-bold",
);
