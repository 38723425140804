import { UnderlinedSectionHeader, ImgCarousel } from "@components";
import { teamImages } from "./constants";
import clsx from "clsx";

export default function WhoWeAre() {
    return (
        <>
            <UnderlinedSectionHeader>Who We Are</UnderlinedSectionHeader>
            <ImgCarousel images={teamImages} className="mt-10" />
            <div className={textContainerStyle}>
                CTrees is a nonprofit founded in 2022 to bring scientific data
                and knowledge to the global community implementing nature-based
                climate solutions. We provide operational data and insights on
                forests globally to governments and organizations seeking to
                track carbon, report on deforestation, and design
                emissions-reduction strategies.
                <div className="pt-4">
                    CTrees is led by Sassan Saatchi, a senior research scientist
                    at NASA Jet Propulsion Lab / Caltech and adjunct professor
                    at UCLA’s Center for Tropical Research. CTrees’ team of
                    scientific leaders, engineers, and policy experts have more
                    than two decades of experience in building global carbon
                    monitoring systems and solutions that have informed climate
                    change policies.
                </div>
                <div className="pt-4">
                    Our staff includes more than a dozen scientists who have
                    conducted field research in the Amazon and Congo basins, and
                    earned doctorates in remote sensing and forest ecology from
                    leading universities in Brazil, Cameroon, Mexico, the
                    Netherlands, and the United States. CTrees is headquartered
                    in Pasadena, drawing on Southern California’s leadership in
                    remote sensing technology and academic centers for forest
                    science and policy. The initial research that seeded CTrees
                    was conducted at UCLA Institute of the Environment &
                    Sustainability.
                </div>
            </div>
        </>
    );
}

const textContainerStyle = clsx(
    "text-xl max-w-[800px] pt-10 p-4",
    "mb-20 lg:mb-40 text-center md:text-left",
);
