export default function ClusterPie({ className, alt }) {
    return (
        <div
            role={alt ? "img" : "presentation"}
            aria-label={alt || undefined}
            aria-hidden={alt ? undefined : "true"}
            className={className}
        >
            <svg
                width="100%"
                height="100%"
                viewBox="8 0 365 351"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M189.193 319.682V31.708"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    stroke-dasharray="2 2"
                />
                <path
                    d="M45.2065 175.695L333.181 175.695"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    stroke-dasharray="2 2"
                />
                <path
                    d="M87.3793 277.509L291.008 73.8809"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    stroke-dasharray="2 2"
                />
                <path
                    d="M87.3793 73.8813L291.008 277.51"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    stroke-dasharray="2 2"
                />
                <path
                    d="M189.194 287.851C251.168 287.851 301.408 237.611 301.408 175.637C301.408 113.663 251.168 63.4229 189.194 63.4229C127.219 63.4229 76.9795 113.663 76.9795 175.637C76.9795 237.611 127.219 287.851 189.194 287.851Z"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M189.193 350.127C285.562 350.127 363.684 272.005 363.684 175.637C363.684 79.2684 285.562 1.14648 189.193 1.14648C92.8251 1.14648 14.7031 79.2684 14.7031 175.637C14.7031 272.005 92.8251 350.127 189.193 350.127Z"
                    stroke="black"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M189.194 231.746C220.182 231.746 245.303 206.625 245.303 175.637C245.303 144.648 220.182 119.527 189.194 119.527C158.205 119.527 133.084 144.648 133.084 175.637C133.084 206.625 158.205 231.746 189.194 231.746Z"
                    stroke="#9B9B9B"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                />
                <circle cx="183.831" cy="149.606" r="8" fill="#FF6B6B" />
                <circle cx="255.825" cy="202.869" r="8" fill="#FF6B6B" />
                <circle cx="255.825" cy="319.547" r="8" fill="#FF6B6B" />
                <circle cx="307.184" cy="266.934" r="8" fill="#FF6B6B" />
                <circle cx="229.215" cy="39.7969" r="8" fill="#FF6B6B" />
                <circle cx="323.184" cy="134.099" r="8" fill="#FF6B6B" />
                <circle cx="327.818" cy="201.385" r="8" fill="#FF6B6B" />
                <circle cx="135.722" cy="32.2969" r="8" fill="#FF6B6B" />
                <circle cx="72.498" cy="102.099" r="8" fill="#FF6B6B" />
                <circle
                    cx="164.618"
                    cy="167.033"
                    r="8"
                    transform="rotate(37.3688 164.618 167.033)"
                    fill="#46CE84"
                />
                <circle
                    cx="158.191"
                    cy="207.882"
                    r="8"
                    transform="rotate(37.3688 158.191 207.882)"
                    fill="#46CE84"
                />
                <circle
                    cx="66.0005"
                    cy="126.098"
                    r="8"
                    transform="rotate(37.3688 66.0005 126.098)"
                    fill="#46CE84"
                />
                <circle
                    cx="121.266"
                    cy="226.111"
                    r="8"
                    transform="rotate(37.3688 121.266 226.111)"
                    fill="#46CE84"
                />
                <circle
                    cx="119.764"
                    cy="300.334"
                    r="8"
                    transform="rotate(37.3688 119.764 300.334)"
                    fill="#46CE84"
                />
                <circle
                    cx="69.2846"
                    cy="266.934"
                    r="8"
                    transform="rotate(37.3688 69.2846 266.934)"
                    fill="#46CE84"
                />
                <circle
                    cx="48.4477"
                    cy="209.385"
                    r="8"
                    transform="rotate(37.3688 48.4477 209.385)"
                    fill="#46CE84"
                />
                <path
                    d="M229.585 39.7966L134.541 32.2969L72.498 101.86L181.919 148.243L254.781 201.727V319.547L310.59 264.513L329.193 201.727L323.184 133.395L229.585 39.7966Z"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <circle cx="161.404" cy="316.611" r="8" fill="#B6D9FF" />
                <circle cx="219.924" cy="327.589" r="8" fill="#B6D9FF" />
                <circle cx="225.505" cy="213.324" r="8" fill="#B6D9FF" />
                <circle cx="188.547" cy="190.172" r="8" fill="#B6D9FF" />
                <circle cx="146.978" cy="247.721" r="8" fill="#B6D9FF" />
                <circle cx="180.547" cy="228.599" r="8" fill="#B6D9FF" />
                <circle cx="239.941" cy="260.507" r="8" fill="#B6D9FF" />
                <path
                    d="M239.778 259.908L227.019 213.24L189.089 188.139L182.685 228.485L147.289 246.987L163.202 318.11L219.21 327.588L239.778 259.908Z"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M49.1411 209.404L69.3131 264.513L122.701 302.922L122.701 225.998L159.718 207.285L164.091 166.071L126.11 153.669L65.6495 126.539L49.1411 209.404Z"
                    stroke="#46CE84"
                    strokeWidth="3"
                />
            </svg>
        </div>
    );
}
