export default function AboutHero({ className }) {
    return (
        <svg
            className={className}
            width="723"
            height="433"
            viewBox="0 0 723 433"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M519.253 397.946C519.253 305.334 665.586 253.899 665.586 342.515"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M133.108 316.187C133.108 171.94 520.068 177.28 520.068 396.05"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M570.64 269.516C570.64 201.661 662.871 266.288 665.956 343.105"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M550.667 189.276C550.667 123.245 609.045 64.623 609.045 142.093"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M550.929 187.236C550.929 89.8193 691.141 90.3823 691.141 211.45"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M497.159 242.322C503.142 215.007 569.913 216.303 569.913 268.058"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M497.015 243.273C497.014 195.371 550.532 139.729 550.532 190.109"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M328.042 177.738C328.042 73.646 431.9 -8.44169 431.9 101.633"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M327.94 177.664C327.94 57.1593 491.241 0.848892 496.872 242.421"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M521.085 395.585C521.085 287.022 571.765 149.508 571.765 268.323"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M133.983 318.344C133.983 118.954 328.006 45.1661 328.006 177.984"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M133.616 318.428C133.616 206.079 398.547 231.286 398.547 373.962"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M8.03418 410.632C8.03418 282.93 133.738 219.913 133.738 319.077"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M608.757 141.821C608.757 32.813 715.54 -53.408 715.54 41.2872"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M399.349 374.332C399.349 168.044 347.362 90.3856 327.589 174.785"
                stroke="white"
                strokeWidth="0.7"
                stroke-dasharray="3 3"
            />
            <path
                d="M714.353 62.6226L712.682 66.1226H716.724L715.053 62.6226H714.353ZM715.053 62.9726V41.3668H714.353V62.9726H715.053Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="714.673"
                cy="41.367"
                r="5.75521"
                transform="rotate(-27.1602 714.673 41.367)"
                fill="#DFF841"
            />
            <path
                d="M133.353 339.604L131.682 343.104H135.724L134.053 339.604H133.353ZM134.053 339.954V318.349H133.353V339.954H134.053Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="133.673"
                cy="318.349"
                r="5.75521"
                transform="rotate(-27.1602 133.673 318.349)"
                fill="#DFF841"
            />
            <path
                d="M7.64609 429.252L5.97537 432.752H10.0168L8.34609 429.252H7.64609ZM8.34609 429.602L8.34609 407.997H7.64609L7.64609 429.602H8.34609Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="7.96551"
                cy="407.997"
                r="5.75521"
                transform="rotate(-27.1602 7.96551 407.997)"
                fill="#DFF841"
            />
            <path
                d="M431.49 123.35L429.819 126.85H433.861L432.19 123.35H431.49ZM432.19 123.7V102.094H431.49V123.7H432.19Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="431.809"
                cy="102.094"
                r="5.75521"
                transform="rotate(-27.1602 431.809 102.094)"
                fill="#DFF841"
            />
            <path
                d="M571.134 291.122L569.464 294.622H573.505L571.834 291.122H571.134ZM571.834 291.472V269.866H571.134V291.472H571.834Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="571.454"
                cy="269.866"
                r="5.75521"
                transform="rotate(-27.1602 571.454 269.866)"
                fill="#DFF841"
            />
            <path
                d="M690.181 232.371L688.511 235.871H692.552L690.881 232.371H690.181ZM690.881 232.721V211.115H690.181V232.721H690.881Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="690.501"
                cy="211.116"
                r="5.75521"
                transform="rotate(-27.1602 690.501 211.116)"
                fill="#DFF841"
            />
            <path
                d="M327.9 199.868L326.229 203.368H330.271L328.6 199.868H327.9ZM328.6 200.218V178.612H327.9V200.218H328.6Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="328.219"
                cy="178.612"
                r="5.75521"
                transform="rotate(-27.1602 328.219 178.612)"
                fill="#DFF841"
            />
            <path
                d="M665.595 363.667L663.925 367.167H667.966L666.295 363.667H665.595ZM666.295 364.017V342.411H665.595V364.017H666.295Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="665.915"
                cy="342.411"
                r="5.75521"
                transform="rotate(-27.1602 665.915 342.411)"
                fill="#DFF841"
            />
            <path
                d="M497.228 263.363L495.557 266.863H499.599L497.928 263.363H497.228ZM497.928 263.713V242.107H497.228V263.713H497.928Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="497.548"
                cy="242.107"
                r="5.75521"
                transform="rotate(-27.1602 497.548 242.107)"
                fill="#DFF841"
            />
            <path
                d="M550.673 211.269L549.003 214.769H553.044L551.373 211.269H550.673ZM551.373 211.619V190.013H550.673V211.619H551.373Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="550.993"
                cy="190.013"
                r="5.75521"
                transform="rotate(-27.1602 550.993 190.013)"
                fill="#DFF841"
            />
            <path
                d="M608.255 162.371L606.585 165.871H610.626L608.955 162.371H608.255ZM608.955 162.721V141.115H608.255V162.721H608.955Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="608.575"
                cy="141.116"
                r="5.75521"
                transform="rotate(-27.1602 608.575 141.116)"
                fill="#DFF841"
            />
            <path
                d="M520.505 417.712L518.835 421.212H522.876L521.205 417.712H520.505ZM521.205 418.062V396.456H520.505V418.062H521.205Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="520.825"
                cy="396.456"
                r="5.75521"
                transform="rotate(-27.1602 520.825 396.456)"
                fill="#DFF841"
            />
            <path
                d="M398.224 396.749L396.553 400.249H400.595L398.924 396.749H398.224ZM398.924 397.099V375.493H398.224V397.099H398.924Z"
                fill="white"
                fillOpacity="0.64"
            />
            <circle
                cx="398.544"
                cy="375.493"
                r="5.75521"
                transform="rotate(-27.1602 398.544 375.493)"
                fill="#DFF841"
            />
        </svg>
    );
}
