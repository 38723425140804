import { useState, useEffect } from "react";
import clsx from "clsx";
import { DropdownArrowIcon } from "@assets/icons";
import { copyToClipboard } from "@helpers";
import * as styles from "./styles";

export default function Timelapse({ images, coordinates, caption }) {
    const [currentIndex, setCurrentIndex] = useState(4);
    const [copied, setCopied] = useState(false);
    const { lat, lon } = coordinates;
    const coordinatesString = `Lat ${lat}, Long ${lon}`;

    useEffect(() => {
        if (copied) {
            setInterval(() => {
                setCopied(false);
            }, 3000);
        }
    }, [copied]);

    return (
        <div className={styles.container}>
            <div className={styles.imgWrapper}>
                <div className={styles.copiedContainer}>
                    {copied ? (
                        <div className="w-1/5 rounded-full bg-green">
                            {"Copied!"}
                        </div>
                    ) : (
                        "Click coordinates to copy."
                    )}
                </div>
                <div
                    onClick={() =>
                        copyToClipboard(coordinatesString, setCopied)
                    }
                    className={styles.coords}
                >
                    <div>Lat {lat}</div>
                    <div>Long {lon}</div>
                </div>
                <img
                    src={images[currentIndex].src}
                    alt={`Year ${images[currentIndex].year}`}
                    className="h-full w-full rounded-lg object-cover"
                />

                <div className={styles.navigation}>
                    <NavArrow
                        onClick={() =>
                            setCurrentIndex(
                                (currentIndex - 1 + images.length) %
                                    images.length,
                            )
                        }
                    />
                    {images.map((image, index) => (
                        <div
                            key={image.year}
                            className={clsx(
                                "flex cursor-pointer text-sm",
                                index === currentIndex
                                    ? "text-green"
                                    : "text-white/70",
                            )}
                            onClick={() => setCurrentIndex(index)}
                        >
                            {index === 0 && (
                                <div className="mx-2 text-white/40">|</div>
                            )}
                            {image.year}
                            <div className="mx-2 text-white/40">|</div>
                        </div>
                    ))}
                    <NavArrow
                        right={true}
                        onClick={() =>
                            setCurrentIndex((currentIndex + 1) % images.length)
                        }
                    />
                </div>
                <div className={styles.caption}>
                    Interactive timelapse of {caption}.
                </div>
            </div>
        </div>
    );
}

function NavArrow({ right = false, onClick }) {
    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault()
        onClick();
    };
    return (
        <DropdownArrowIcon
            onClick={handleClick}
            fill="#fff8"
            className={clsx(
                "h-[8px] cursor-pointer ",
                right ? " right-0 rotate-[270deg]" : " rotate-90",
            )}
        />
    );
}

