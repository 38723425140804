export default function LineChart({ className, alt }) {
    return (
        <div role="img" aria-label={alt} className={className}>
            <svg
                className="w-full h-full"
                viewBox="0 0 452 329"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.424 263.44C12.24 263.44 10.714 261.788 10.714 259.506V257.098C10.714 254.83 12.24 253.178 14.424 253.178C16.594 253.178 18.12 254.83 18.12 257.098V259.506C18.12 261.788 16.594 263.44 14.424 263.44ZM14.424 261.55C15.488 261.55 16.202 260.794 16.202 259.506V257.098C16.202 255.81 15.488 255.068 14.424 255.068C13.36 255.068 12.632 255.81 12.632 257.098V259.506C12.632 260.794 13.36 261.55 14.424 261.55Z"
                    fill="black"
                />
                <path
                    d="M3.976 169.44C2.156 169.44 0.504 168.18 0.434 166.402H2.422C2.59 167.06 3.206 167.55 3.976 167.55C4.97 167.55 5.53 166.864 5.53 166.01C5.53 165.128 4.9 164.47 4.004 164.47C3.22 164.47 2.674 164.904 2.45 165.506L0.756 164.988L1.876 159.346H6.72V161.208H3.36L2.982 163.07C3.318 162.86 3.794 162.734 4.354 162.734C6.244 162.734 7.448 164.148 7.448 166.164C7.448 168.152 6.048 169.44 3.976 169.44ZM13.8357 169.44C11.6517 169.44 10.1257 167.788 10.1257 165.506V163.098C10.1257 160.83 11.6517 159.178 13.8357 159.178C16.0057 159.178 17.5317 160.83 17.5317 163.098V165.506C17.5317 167.788 16.0057 169.44 13.8357 169.44ZM13.8357 167.55C14.8997 167.55 15.6137 166.794 15.6137 165.506V163.098C15.6137 161.81 14.8997 161.068 13.8357 161.068C12.7717 161.068 12.0437 161.81 12.0437 163.098V165.506C12.0437 166.794 12.7717 167.55 13.8357 167.55Z"
                    fill="black"
                />
                <path
                    d="M2.128 75.2861V67.2361H0.028L1.428 65.3461H4.046V75.2861H2.128ZM10.9783 75.4401C8.7943 75.4401 7.2683 73.7881 7.2683 71.5061V69.0981C7.2683 66.8301 8.7943 65.1781 10.9783 65.1781C13.1483 65.1781 14.6743 66.8301 14.6743 69.0981V71.5061C14.6743 73.7881 13.1483 75.4401 10.9783 75.4401ZM10.9783 73.5501C12.0423 73.5501 12.7563 72.7941 12.7563 71.5061V69.0981C12.7563 67.8101 12.0423 67.0681 10.9783 67.0681C9.9143 67.0681 9.1863 67.8101 9.1863 69.0981V71.5061C9.1863 72.7941 9.9143 73.5501 10.9783 73.5501ZM21.2103 75.4401C19.0263 75.4401 17.5003 73.7881 17.5003 71.5061V69.0981C17.5003 66.8301 19.0263 65.1781 21.2103 65.1781C23.3803 65.1781 24.9063 66.8301 24.9063 69.0981V71.5061C24.9063 73.7881 23.3803 75.4401 21.2103 75.4401ZM21.2103 73.5501C22.2743 73.5501 22.9883 72.7941 22.9883 71.5061V69.0981C22.9883 67.8101 22.2743 67.0681 21.2103 67.0681C20.1463 67.0681 19.4183 67.8101 19.4183 69.0981V71.5061C19.4183 72.7941 20.1463 73.5501 21.2103 73.5501Z"
                    fill="black"
                />
                <path
                    d="M85.0898 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M144.41 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M203.73 1.21582V296.679"
                    stroke="black"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M263.051 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M322.371 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M381.691 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <path
                    d="M441 1.21582V296.679"
                    stroke="#9B9B9B"
                    strokeWidth="2"
                    stroke-dasharray="4 4"
                />
                <circle cx="204.271" cy="78.8145" r="8" fill="#FF6B6B" />
                <circle
                    cx="204.271"
                    cy="79.5039"
                    r="18.6025"
                    stroke="#FF6B6B"
                    strokeWidth="2"
                />
                <path
                    d="M51.5 237.286L133.5 226.786L203.77 194.441L313 237.286L385.5 226.786L447 208.786"
                    stroke="#46CE84"
                    strokeWidth="3"
                />
                <path
                    d="M51 157.286H145.5L203.711 77.8398L235.172 103.56L262.758 125.983L323.053 117.369L356.45 186.725L412 149.286L441.5 157.286"
                    stroke="#FF6B6B"
                    strokeWidth="3"
                />
                <path
                    d="M55 134.286H89L104.728 166.817L143.659 188.494L189.668 142.928L236.5 134.286L265.759 184.955L320.5 134.286H350L389 119.786H441"
                    stroke="#B6D9FF"
                    strokeWidth="3"
                />
                <path
                    d="M40.5 0.786133V297.786H452"
                    stroke="black"
                    strokeWidth="2"
                />
                <circle cx="204.271" cy="196.494" r="8" fill="#46CE84" />
                <circle
                    cx="203.73"
                    cy="140.628"
                    r="7.5"
                    fill="#B6D9FF"
                    stroke="#B6D9FF"
                />
                <path d="M36.1602 70.8145H44.8398" stroke="black" />
                <path d="M36.1602 164.286H44.8398" stroke="black" />
                <path d="M36.1602 258.286H44.8398" stroke="black" />
                <path d="M85.0898 293.446L85.0898 302.126" stroke="black" />
                <path d="M144.41 293.446L144.41 302.126" stroke="black" />
                <path d="M203.73 293.446L203.73 302.126" stroke="black" />
                <path d="M263.051 293.446L263.051 302.126" stroke="black" />
                <path d="M322.371 293.446L322.371 302.126" stroke="black" />
                <path d="M381.691 293.446L381.691 302.126" stroke="black" />
                <path d="M441 293.446L441 302.126" stroke="black" />
                <path
                    d="M193.122 324.566V326.456H186.346V325.798C186.346 323.572 188.096 322.62 189.552 321.57C190.42 320.968 191.12 320.324 191.12 319.526C191.12 318.798 190.658 318.238 189.804 318.238C188.922 318.238 188.292 318.84 188.306 319.638H186.374C186.374 317.678 187.872 316.348 189.846 316.348C191.512 316.348 193.038 317.398 193.038 319.414C193.038 321.122 192.198 321.99 190.938 322.914C190.322 323.348 189.258 323.936 189.006 324.566H193.122ZM199.622 326.61C197.438 326.61 195.912 324.958 195.912 322.676V320.268C195.912 318 197.438 316.348 199.622 316.348C201.792 316.348 203.318 318 203.318 320.268V322.676C203.318 324.958 201.792 326.61 199.622 326.61ZM199.622 324.72C200.686 324.72 201.4 323.964 201.4 322.676V320.268C201.4 318.98 200.686 318.238 199.622 318.238C198.558 318.238 197.83 318.98 197.83 320.268V322.676C197.83 323.964 198.558 324.72 199.622 324.72ZM207.558 326.456V318.406H205.458L206.858 316.516H209.476V326.456H207.558ZM219.334 324.566V326.456H212.558V325.798C212.558 323.572 214.308 322.62 215.764 321.57C216.632 320.968 217.332 320.324 217.332 319.526C217.332 318.798 216.87 318.238 216.016 318.238C215.134 318.238 214.504 318.84 214.518 319.638H212.586C212.586 317.678 214.084 316.348 216.058 316.348C217.724 316.348 219.25 317.398 219.25 319.414C219.25 321.122 218.41 321.99 217.15 322.914C216.534 323.348 215.47 323.936 215.218 324.566H219.334Z"
                    fill="black"
                />
            </svg>
        </div>
    );
}
